<template>
    <div @click="active.chartShow ? active.chartShow = false : void 0">
        <van-nav-bar title="设备详情" left-text="返回" @click-left="$router.go(-1)" left-arrow>
             <template #right>
                <van-icon name="apps-o" size="18" @click="showSystem"/>
            </template>
        </van-nav-bar>
        <div class="title"><p>{{info.devName || '加载中...'}}({{info.status=='online' ? '在线': info.status=='offline' ?'离线':'...'}})</p><p>({{info.gpsLat || '0.00'}},{{info.gpsLon || '0.00'}})</p></div>
        <div class="panel">
            <img v-if="!active.chartShow" :src="imgStatus" @click.stop>
            <canvas v-else id="batteryCurve" @click.stop></canvas>
            <div class="radar-location" v-if="!active.chartShow">
                <div class="name">雷达监测区域</div>
                <img :src="require('@/assets/pah/img/radar/'+img.radar+'.png')">
                <div class="radar-status">
                    <div>工作中<span :class="'square ' +(info.status=='online' ? 'working' :'')"></span></div>
                    <div>非工作<span :class="'square ' +(info.status=='offline' ? 'working' :'')"></span></div>
                </div> 
            </div>
            <div class="icon-btn" v-if="!active.chartShow">
                <van-button size="small" color="#45a781" icon="notes-o" type="primary" @click.stop="drawChart">电量</van-button>
            </div>
        </div>
        <div class="info">
            <van-row>
                <van-col span="12" :class="+info.batPow <= 20 ?'red':''">电池电量：{{info.batPow || 100}}%</van-col>
                <van-col span="12" :class="+info.temppcb2 > 65 ?'red':''">工作温度：{{info.temppcb2 || 15}}℃</van-col>
            </van-row>
            <div>按键次数：{{info.alarmNum || 0}}</div>
            <div>语音状态：{{info.lable || '暂无'}} 音量：{{info.volume || '无'}}</div>
            <div>警示灯状态：{{info.workMode=='0'?'关闭':info.workMode=='1'?'常闪':info.workMode=='2'?'触发':info.workMode=='3'?'自定义':'暂无'}}-{{info.flashMode=='0'?'旋转闪烁':info.flashMode=='1'?'红蓝交替':info.flashMode=='2'?'左右闪烁':'暂无'}}</div>
            <div>路由状态：{{info.routerMode=='0' ?'整点在线':info.routerMode=='1'?'始终在线':info.routerMode=='2'?'预约上线':'暂无'}}</div>
            <div>更新时间：{{info.msgTime ||'2020-01-01 00:00:00'}}</div>
        </div>
        <div class="nav">
            <van-row>
                <van-col v-for="(item,index) in controlData" :class="{userActive:active.control==item.name}" :key="index" span="4" @click="dialogShow(item.name)">
                    <i :class="'fa '+item.icon"></i>
                    <div>{{item.text}}</div>
                </van-col>
            </van-row>
        </div>
        <!-- 弹出组件汇总 -->
        <van-dialog v-model="dialog.dev" title="设备系统操作" :show-confirm-button="false" close-on-click-overlay>
            <div class="margin-block">
                <van-row>
                    <van-col span="8" @click="restart">
                        <div><i class="fa fa-spinner"></i></div><div>重启</div>
                    </van-col>
                    <van-col span="8">
                        <div><van-switch v-model="system.camPwr1" active-color="#45a781" :active-value='1' :inactive-value='0' @change="systemSave"/></div><div>相机1</div>
                        </van-col>
                    <van-col span="8">
                        <div><van-switch v-model="system.camPwr2" active-color="#45a781" :active-value='1' :inactive-value='0' @change="systemSave"/></div><div>相机2</div>
                    </van-col>
                </van-row>
            </div>
        </van-dialog>
        <van-dialog v-model="dialog.mode" title="工作模式控制" show-cancel-button close-on-click-overlay confirmButtonText="发送" confirmButtonColor="#2F9E73" width="380" @confirm="modeSave">
            <div class="margin-block">
                <van-row>
                    <van-col span="6" v-for="(item,index) in category.modeType" :class="{userActive:active.mode==item.id}" :key="index" @click="modeSelect(item.id)">
                        <div><i class="fa fa-table"></i></div><div v-text="item.text"></div>
                    </van-col>
                </van-row>
                <van-radio-group v-model="mode.flashMode" direction="horizontal" class="space-around">
                    <van-radio name="0" checked-color="#45a781">旋转闪烁</van-radio>
                    <van-radio name="1" checked-color="#45a781">红蓝交替</van-radio>
                    <van-radio name="2" checked-color="#45a781">左右闪烁</van-radio>
                </van-radio-group>
            </div>
        </van-dialog>
        <van-dialog v-model="dialog.router" title="路由器控制" show-cancel-button close-on-click-overlay confirmButtonText="发送" confirmButtonColor="#2F9E73" width="380" @confirm="routerSave">
             <div class="margin-block">
                <van-row>
                    <van-col  span="6"  v-for="(item,index) in router.type" :class="{userActive:active.router==item.id}" :key="index" @click="routerSelect(item.id)">
                        <div><i class="fa fa-dashboard"></i></div><div v-text="item.text"></div>
                    </van-col>
                </van-row>
                <van-field v-model="router.holdTime" label="保持时长(m)" placeholder="请输入保持时长" />
                <div v-if="router.routerMode == '2' || router.routerMode == '3'">
                    <van-field v-model="router.routerTime1" label="预约时间1" placeholder="请点击选择预约时间" readonly @click="routerPicker(1)"/>
                    <van-field v-model="router.routerTime2" label="预约时间2" placeholder="请点击选择预约时间" readonly @click="routerPicker(2)"/>
                    <van-field v-model="router.routerTime3" label="预约时间3" placeholder="请点击选择预约时间" readonly @click="routerPicker(3)"/>
                    <van-field v-model="router.routerTime4" label="预约时间4" placeholder="请点击选择预约时间" readonly @click="routerPicker(4)"/>
                    <van-field v-model="router.routerTime5" label="预约时间5" placeholder="请点击选择预约时间" readonly @click="routerPicker(5)"/>
                </div>
            </div>
        </van-dialog>
        <van-dialog v-model="dialog.projection" title="投影控制" show-cancel-button close-on-click-overlay confirmButtonText="发送" confirmButtonColor="#2F9E73" width="380" @confirm="projectionSave">
            <div class="margin-block">
                <div>
                    <van-radio-group v-model="projection.optType" direction="horizontal">
                        <span>控制类型:</span>
                        <van-radio name="0" checked-color="#45a781" style="margin-right:2%">关</van-radio>
                        <van-radio name="1" checked-color="#45a781" style="margin-right:2%">开</van-radio>
                        <van-radio name="2" checked-color="#45a781" style="margin-right:2%">光感控制</van-radio>
                        <van-radio name="3" checked-color="#45a781" style="margin-right:2%">时间控制</van-radio>
                    </van-radio-group>
                </div>
                <div class="flex">
                    <div class="w_20">投影亮度:</div>
                    <div class="w_80">
                        <van-slider v-model="projection.brightness" active-color="#2F9E73">
                            <template #button>
                                <div class="custom-button">{{projection.brightness}}</div>
                            </template>
                        </van-slider>
                    </div>
                </div>
                <div class="flex" v-if="this.projection.optType == '2'">
                    <div class="w_20">投影光感:</div>
                    <div class="w_80">
                        <van-slider v-model="projection.threshold" active-color="#2F9E73" :min="0" :max="4096">
                            <template #button>
                                <div class="custom-button">{{projection.threshold}}</div>
                            </template>
                        </van-slider>
                    </div>
                </div>
                <div class="time pad-2" v-if="this.projection.optType == '3'">
                     <van-row>
                        <van-col span="6" class="title-small">开始时间:</van-col>
                        <van-col span="6" @click="projectionPicker('start')">{{projection.startTime || '无'}}</van-col>
                        <van-col span="6" class="title-small">结束时间:</van-col>
                        <van-col span="6" @click="projectionPicker('stop')">{{projection.stopTime || '无'}}</van-col>
                    </van-row>
                </div>
            </div>
        </van-dialog>
        <van-dialog v-model="dialog.radar" title="雷达控制" show-cancel-button close-on-click-overlay confirmButtonText="发送" confirmButtonColor="#2F9E73" width="380" @confirm="radarSave">
            <div class="margin-block">
                <van-row>
                    <van-col span="6">
                        <div><van-switch v-model="radar.radarNo1" active-color="#45a781" :active-value='1' :inactive-value='0'/></div><div>雷达1</div>
                        </van-col>
                    <van-col span="6">
                        <div><van-switch v-model="radar.radarNo2" active-color="#45a781" :active-value='1' :inactive-value='0'/></div><div>雷达2</div>
                    </van-col>
                    <van-col span="6">
                        <div><van-switch v-model="radar.radarNo3" active-color="#45a781" :active-value='1' :inactive-value='0'/></div><div>雷达3</div>
                    </van-col>
                    <van-col span="6">
                        <div><van-switch v-model="radar.radarNo4" active-color="#45a781" :active-value='1' :inactive-value='0'/></div><div>雷达4</div>
                    </van-col>
                </van-row>
            </div>
        </van-dialog>
        <van-dialog v-model="dialog.broadcast" title="播报控制" show-cancel-button close-on-click-overlay confirmButtonText="发送" confirmButtonColor="#2F9E73" width="380" @confirm="broadcastSave">
            <div class="margin-block">
                <div>
                    <van-radio-group v-model="broadcast.optType" direction="horizontal">
                        <span>控制类型:</span>
                        <van-radio name="0" checked-color="#45a781" style="margin-right:2%">关</van-radio>
                        <van-radio name="1" checked-color="#45a781" style="margin-right:2%">开</van-radio>
                        <van-radio name="2" checked-color="#45a781" style="margin-right:2%">光感控制</van-radio>
                        <van-radio name="3" checked-color="#45a781" style="margin-right:2%">时间控制</van-radio>
                    </van-radio-group>
                </div>
                <div class="flex">
                    <div class="w_20">播报音量:</div>
                    <div class="w_80">
                        <van-slider v-model="broadcast.volume" active-color="#2F9E73" :min="0" :max="16">
                            <template #button>
                                <div class="custom-button">{{broadcast.volume}}</div>
                            </template>
                        </van-slider>
                    </div>
                </div>
                <div class="flex">
                    <div class="w_20">语音节目:</div>
                    <div @click="broadcast.pickerPro = true">{{broadcast.program}}</div>
                </div>
                <div class="flex" v-if="this.broadcast.optType == 2">
                    <div class="w_20">投影光感:</div>
                    <div class="w_80">
                        <van-slider v-model="broadcast.threShold" active-color="#2F9E73" :min="0" :max="4096">
                            <template #button>
                                <div class="custom-button">{{broadcast.threShold}}</div>
                            </template>
                        </van-slider>
                    </div>
                </div>
                <div class="time pad-2" v-if="this.broadcast.optType == 3">
                     <van-row>
                        <van-col span="6" class="title-small">开始时间:</van-col>
                        <van-col span="6" @click="broadcastPicker('start')">{{broadcast.startTime || '无'}}</van-col>
                        <van-col span="6" class="title-small">结束时间:</van-col>
                        <van-col span="6" @click="broadcastPicker('stop')">{{broadcast.stopTime || '无'}}</van-col>
                    </van-row>
                </div>
            </div>
        </van-dialog>
        <van-dialog v-model="dialog.definedMode" title="自定义工作模式控制" show-cancel-button close-on-click-overlay confirmButtonText="发送" confirmButtonColor="#2F9E73" width="380" @confirm="modeSave">
            <div class="margin-block">
                <div>
                    <div class="time">
                        <van-row>
                            <van-col span="6" class="title-small">开始时间:</van-col>
                            <van-col span="6" @click="openPicker('time1','start')">{{mode.time1Start || '无'}}</van-col>
                            <van-col span="6" class="title-small">结束时间:</van-col>
                            <van-col span="6" @click="openPicker('time1','end')">{{mode.time1End || '无'}}</van-col>
                        </van-row>
                    </div>
                    <div class="flex-x">
                        <div class="w_20-2 title-small">
                            工作模式:
                        </div>
                        <div class="w_80-2">
                            <van-radio-group v-model="mode.time1Mode" direction="horizontal" class="margin-0">
                                <van-radio name="0" checked-color="#45a781" style="margin-right:12%">关闭</van-radio>
                                <van-radio name="1" checked-color="#45a781" style="margin-right:12%">常闪</van-radio>
                                <van-radio name="2" checked-color="#45a781" style="margin-right:12%">触发</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div class="flex-x">
                        <div class="w_20-2 title-small">
                            闪烁模式:
                        </div>
                        <div class="w_80-2">
                            <van-radio-group v-model="mode.time1Flash" direction="horizontal" class=" margin-0">
                                <van-radio name="0" checked-color="#45a781" style="margin-right:2%">旋转闪烁</van-radio>
                                <van-radio name="1" checked-color="#45a781" style="margin-right:2%">红蓝交替</van-radio>
                                <van-radio name="2" checked-color="#45a781" style="margin-right:2%">左右闪烁</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="time">
                        <van-row>
                            <van-col span="6" class="title-small">开始时间:</van-col>
                            <van-col span="6" @click="openPicker('time2','start')">{{mode.time2Start || '无'}}</van-col>
                            <van-col span="6" class="title-small">结束时间:</van-col>
                            <van-col span="6" @click="openPicker('time2','end')">{{mode.time2End || '无'}}</van-col>
                        </van-row>
                    </div>
                    <div class="flex-x">
                        <div class="w_20-2 title-small">
                            工作模式:
                        </div>
                        <div class="w_80-2">
                            <van-radio-group v-model="mode.time2Mode" direction="horizontal" class="margin-0">
                                <van-radio name="0" checked-color="#45a781" style="margin-right:12%">关闭</van-radio>
                                <van-radio name="1" checked-color="#45a781" style="margin-right:12%">常闪</van-radio>
                                <van-radio name="2" checked-color="#45a781" style="margin-right:12%">触发</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div class="flex-x">
                        <div class="w_20-2 title-small">
                            闪烁模式:
                        </div>
                        <div class="w_80-2">
                            <van-radio-group v-model="mode.time2Flash" direction="horizontal" class=" margin-0">
                                <van-radio name="0" checked-color="#45a781" style="margin-right:2%">旋转闪烁</van-radio>
                                <van-radio name="1" checked-color="#45a781" style="margin-right:2%">红蓝交替</van-radio>
                                <van-radio name="2" checked-color="#45a781" style="margin-right:2%">左右闪烁</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="time">
                        <van-row>
                            <van-col span="6" class="title-small">开始时间:</van-col>
                            <van-col span="6" @click="openPicker('time3','start')">{{mode.time3Start || '无'}}</van-col>
                            <van-col span="6" class="title-small">结束时间:</van-col>
                            <van-col span="6" @click="openPicker('time3','end')">{{mode.time3End || '无'}}</van-col>
                        </van-row>
                    </div>
                    <div class="flex-x">
                        <div class="w_20-2 title-small">
                            工作模式:
                        </div>
                        <div class="w_80-2">
                            <van-radio-group v-model="mode.time3Mode" direction="horizontal" class="margin-0">
                                <van-radio name="0" checked-color="#45a781" style="margin-right:12%">关闭</van-radio>
                                <van-radio name="1" checked-color="#45a781" style="margin-right:12%">常闪</van-radio>
                                <van-radio name="2" checked-color="#45a781" style="margin-right:12%">触发</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div class="flex-x">
                        <div class="w_20-2 title-small">
                            闪烁模式:
                        </div>
                        <div class="w_80-2">
                            <van-radio-group v-model="mode.time3Flash" direction="horizontal" class=" margin-0">
                                <van-radio name="0" checked-color="#45a781" style="margin-right:2%">旋转闪烁</van-radio>
                                <van-radio name="1" checked-color="#45a781" style="margin-right:2%">红蓝交替</van-radio>
                                <van-radio name="2" checked-color="#45a781" style="margin-right:2%">左右闪烁</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="time">
                        <van-row>
                            <van-col span="6" class="title-small">开始时间:</van-col>
                            <van-col span="6" @click="openPicker('time4','start')">{{mode.time4Start || '无'}}</van-col>
                            <van-col span="6" class="title-small">结束时间:</van-col>
                            <van-col span="6" @click="openPicker('time4','end')">{{mode.time4End || '无'}}</van-col>
                        </van-row>
                    </div>
                    <div class="flex-x">
                        <div class="w_20-2 title-small">
                            工作模式:
                        </div>
                        <div class="w_80-2">
                            <van-radio-group v-model="mode.time4Mode" direction="horizontal" class="margin-0">
                                <van-radio name="0" checked-color="#45a781" style="margin-right:12%">关闭</van-radio>
                                <van-radio name="1" checked-color="#45a781" style="margin-right:12%">常闪</van-radio>
                                <van-radio name="2" checked-color="#45a781" style="margin-right:12%">触发</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div class="flex-x">
                        <div class="w_20-2 title-small">
                            闪烁模式:
                        </div>
                        <div class="w_80-2">
                            <van-radio-group v-model="mode.time4Flash" direction="horizontal" class=" margin-0">
                                <van-radio name="0" checked-color="#45a781" style="margin-right:2%">旋转闪烁</van-radio>
                                <van-radio name="1" checked-color="#45a781" style="margin-right:2%">红蓝交替</van-radio>
                                <van-radio name="2" checked-color="#45a781" style="margin-right:2%">左右闪烁</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="time">
                        <van-row>
                            <van-col span="6" class="title-small">开始时间:</van-col>
                            <van-col span="6" @click="openPicker('time5','start')">{{mode.time5Start || '无'}}</van-col>
                            <van-col span="6" class="title-small">结束时间:</van-col>
                            <van-col span="6" @click="openPicker('time5','end')">{{mode.time5End || '无'}}</van-col>
                        </van-row>
                    </div>
                    <div class="flex-x">
                        <div class="w_20-2 title-small">
                            工作模式:
                        </div>
                        <div class="w_80-2">
                            <van-radio-group v-model="mode.time5Mode" direction="horizontal" class="margin-0">
                                <van-radio name="0" checked-color="#45a781" style="margin-right:12%">关闭</van-radio>
                                <van-radio name="1" checked-color="#45a781" style="margin-right:12%">常闪</van-radio>
                                <van-radio name="2" checked-color="#45a781" style="margin-right:12%">触发</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div class="flex-x">
                        <div class="w_20-2 title-small">
                            闪烁模式:
                        </div>
                        <div class="w_80-2">
                            <van-radio-group v-model="mode.time5Flash" direction="horizontal" class=" margin-0">
                                <van-radio name="0" checked-color="#45a781" style="margin-right:2%">旋转闪烁</van-radio>
                                <van-radio name="1" checked-color="#45a781" style="margin-right:2%">红蓝交替</van-radio>
                                <van-radio name="2" checked-color="#45a781" style="margin-right:2%">左右闪烁</van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                </div>
            </div>
        </van-dialog>
        <!-- 弹出层选择播报-语音节目 -->
        <van-popup v-model="broadcast.pickerPro" round position="bottom">
            <van-picker show-toolbar :columns="broadcast.proList" @cancel="broadcast.pickerPro = false" @confirm="proConfirm"/>
        </van-popup>
        <!-- 弹出层选择自定义模式-选择时间 -->
        <van-popup v-model="definedMode.picker" round position="bottom">
            <van-datetime-picker type="time" title="选择时间" @confirm="definedTime" @cancel="definedMode.picker=false"/>
        </van-popup>
        <!-- 弹出层选择路由器控制-选择时间 -->
        <van-popup v-model="router.picker" round position="bottom">
            <van-datetime-picker type="time" title="选择时间" @confirm="routerTime"  @cancel="router.picker=false"/>
        </van-popup>
        <!-- 弹出层选择投影控制-选择时间 -->
        <van-popup v-model="projection.picker" round position="bottom">
            <van-datetime-picker type="time" title="选择时间" @confirm="projectionTime" @cancel="projection.picker=false"/>
        </van-popup>
        <!-- 弹出层选择播报控制-选择时间 -->
        <van-popup v-model="broadcast.pickerTime" round position="bottom">
            <van-datetime-picker type="time" title="选择时间" @confirm="broadcastTime" @cancel="broadcast.pickerTime=false"/>
        </van-popup>
    </div>
</template>
<script>
import F2 from '@antv/f2';
export default {
    data(){
        return{
            active:{
                control:'',
                mode:'1',
                router:'1',
                chartData:[],
                chartShow:false,
                waitHint:true
            },
            img:{
                flower:'animal_1',
                radar:'radar'
            },
            info:{},
            system:{
                show:false,
                camPwr1:1,
                camPwr2:1,
            },
            controlData:[
                {icon:'fa-table',text:'工作模式',name:'mode'},
                {icon:'fa-dashboard',text:'路由控制',name:'router'},
                {icon:'fa-road',text:'投影控制',name:'projection'},
                {icon:'fa-bullseye',text:'雷达控制',name:'radar'},
                {icon:'fa-volume-up',text:'播报控制',name:'broadcast'},
                {icon:'fa-caret-square-o-right',text:'实时视频',name:'video'}
            ],
            dialog:{
                dev:false,
                mode:false,
                router:false,
                projection:false,
                radar:false,
                broadcast:false,
                definedMode:false
            },
            category:{
                modeType:[
                    {text:'关闭',id:'0'},
                    {text:'常闪',id:'1'},
                    {text:'触发',id:'2'},
                    {text:'自定义',id:'3'}
                ]
            },
            mode:{},
            definedMode:{
                picker:false,
                name:'',
                type:''
            },
            router:{
                holdTime:5,
                routerMode:0,
                routerTime1:'',
                routerTime2:'',
                routerTime3:'',
                routerTime4:'',
                routerTime5:'',
                type:[
                    {text:'整点在线',id:'0'},
                    {text:'始终在线',id:'1'},
                    {text:'预约上线',id:'2'},
                    {text:'白天在线',id:'3'}
                ],
                picker:false,
                index:''
            },
            projection:{
                optType:'1',
                brightness:86,
                threshold:2048,
                picker:false,
                timeType:'',
                startTime:'',
                stopTime:'',
            },
            radar:{
                radarNo1:1,
                radarNo2:1,
                radarNo3:1,
                radarNo4:1
            },
            broadcast:{
                volume:12,
                broadcast:0,
                pickerPro:false,
                proList: [],
                program:'(普通话)车辆通过',
                optType:'0',
                threShold:2048,
                pickerTime:false,
                timeType:'',
                startTime:'',
                stopTime:'',
            }
        }
    },
    computed:{
        imgStatus(){
            return this.info.status=='1' ? require('@/assets/pah/img/animal/online.jpg') : require('@/assets/pah/img/animal/offline.jpg')
        }
    },
    methods:{
        // 设备重启功能
        restart(){
            let data ={
                devCode:this.info.devCode,
                devName:this.info.devName,
                devType:this.info.devType,
                camPwr1:this.system.camPwr1,
                camPwr2:this.system.camPwr2,
                optType:1
            }
            this.$dialog.confirm({
                title: '重启平安花设备',
                message: '确定重启'+this.info.devName+'吗？',
                confirmButtonColor:'#2F9E73'
            }).then(() => {
                this.$api.PAH.devSystemSave(data).then((d)=>{
                    this.$toast('相机控制发布成功');
                })
                this.$toast('正在重启...');
                setTimeout( ()=>{
                    this.$router.push({path:'/pah/dev'});
                    this.$toast('重启完成...');
                },1000)
            }).catch(()=>{})
        },
        // 设备模式控制
        dialogShow(name){
            if(!this.info.devCode){
                this.$toast('数据正在加载,请稍后重试');
                return false;
            }
            if(this.info.status !='online'){
                this.$toast('设备处于离线模式，待上线后再进行操作');
                return false;
            }
            this.active.control = name;
            switch(name){
                case 'mode':
                    this.dialog.mode = true;
                    this.$api.PAH.devworkMode({devCode:this.$route.query.devCode}).then((d)=>{
                        this.mode = {...d};
                        this.mode.time1Flash = +d.time1Flash;  //数据库原因  转整型
                        this.mode.time1Mode = +d.time1Mode;
                        this.mode.flashMode = d.flashMode.toString();
                        this.active.mode = d.workMode.toString();
                    })
                    break;
                case 'router':
                    this.dialog.router = true;
                    this.$api.PAH.devRouter({devCode:this.$route.query.devCode}).then((d)=>{
                        this.router.holdTime = d.holdTime;
                        this.router.routerMode = d.routerMode;
                        this.router.routerTime1 = d.routerTime1;
                        this.router.routerTime2 = d.routerTime2;
                        this.router.routerTime3 = d.routerTime3;
                        this.router.routerTime4 = d.routerTime4;
                        this.router.routerTime5 = d.routerTime5;
                        this.active.router = d.routerMode;
                    })
                    break;
                case 'projection':
                    this.dialog.projection = true;
                    this.$api.PAH.devProjection({devCode:this.$route.query.devCode}).then((d)=>{
                        this.projection.optType = d.optType;
                        this.projection.brightness = d.brightness;
                        this.projection.threshold = d.threshold;
                        this.projection.startTime = d.startTime;
                        this.projection.stopTime = d.stopTime;
                    })
                    break;
                case 'radar':
                    this.dialog.radar = true;
                    this.$api.PAH.devRadar({devCode:this.$route.query.devCode}).then((d)=>{
                        this.radar.radarNo1 = d.radarNo1;
                        this.radar.radarNo2 = d.radarNo2;
                        this.radar.radarNo3 = d.radarNo3;
                        this.radar.radarNo4 = d.radarNo4;
                    })
                    break;
                case 'broadcast':
                    this.dialog.broadcast = true;
                    this.$api.PAH.devSpeaker({devCode:this.$route.query.devCode}).then((d)=>{
                        this.broadcast.optType = d.optType.toString();
                        this.broadcast.volume = d.volume;
                        this.broadcast.broadcast = d.broadcast;
                        this.broadcast.threShold = d.threShold;
                        this.broadcast.startTime = d.startTime;
                        this.broadcast.stopTime = d.stopTime;
                        this.broadcast.program = this.broadcast.proList.find( k => k.value == d.broadcast ).text;
                    })
                    break;
                default:
                    this.$router.push({path:'../dev/video',query: {devCode:this.$route.query.devCode}})
                    break;
            }
        },
        // 设备模式选择
        modeSelect(id){
            this.active.mode = id;
            this.mode.workMode = id;
            if(this.active.mode == '3'){
                this.dialog.mode = false;
                this.dialog.definedMode = true;
            }
        },
        // 路由模式选择
        routerSelect(id){
            this.active.router = id;
            this.router.routerMode = id;
        },
        // 播报控制-选择节目
        proConfirm(e){
            this.broadcast.program = e.text;
            this.broadcast.broadcast = +e.value;
            this.broadcast.pickerPro=false;
        },
        // 打开自定义模式时间选择器
        openPicker(name,type){
            this.definedMode.picker = true;
            this.definedMode.name = name;
            this.definedMode.type = type;
        },
        // 自定义模式给时间赋值
        definedTime(val){
            if(this.definedMode.type=='start'){
                let text = `${this.definedMode.name}Start`
                this.mode[text] = val;
            }else{
                let text = `${this.definedMode.name}End`
                this.mode[text] = val;
            }
            this.definedMode.picker = false;
        },
        // 打开路由器控制时间选择器
        routerPicker(index){
            this.router.picker = true;
            this.router.index = index;
        },
        // 路由器控制给时间赋值
        routerTime(val){
            let text = `routerTime${this.router.index}`;
            this.router[text] = val;
            this.router.picker = false;
        },
        // 打开投影控制时间选择器
        projectionPicker(type){
            this.projection.picker = true;
            this.projection.timeType = type;
        },
        // 投影控制给时间赋值
        projectionTime(val){
            this.projection.timeType == 'start' ? this.projection.startTime = val : this.projection.stopTime = val;
            this.projection.picker = false;
        },
        // 打开播报控制时间选择器
        broadcastPicker(type){
            this.broadcast.pickerTime = true;
            this.broadcast.timeType = type;
        },
        // 播报控制给时间赋值
        broadcastTime(val){
            this.broadcast.timeType == 'start' ? this.broadcast.startTime = val : this.broadcast.stopTime = val;
            this.broadcast.pickerTime = false;
        },
        // ----------------------------------------------------------------
        // 获取页面信息
        getPageInfo(){
            // 获取该设备信息
            return new Promise((reslove,reject)=>{
                this.$api.PAH.getLastOne({
                    devCode:this.$route.query.devCode
                }).then((d)=>{
                        if(d.length == 0){
                            this.$toast('您暂无该设备权限，请联系管理员');
                            setTimeout( ()=>{
                                this.$router.go(-1);
                            },1000)
                            return false;
                        }
                        this.info = {...d};
                        reslove();
                })
            })
        },
        // 获取语音播报节目单
        getProgramList(){
            this.$api.PAH.getProgram({
                devCode:this.$route.query.devCode
            }).then( d => {
                this.broadcast.proList = d;
                this.broadcast.proList.forEach(element => {
                    element.text = element.lable;
                    delete element.lable;
                });
            })
        },
        // 获取设备系统操作
        getSystem(){
            this.$api.PAH.devSystem({devCode:this.$route.query.devCode}).then((d)=>{
                if(d){
                    this.system.show = true;
                    this.system.camPwr1 = d.camPwr1;
                    this.system.camPwr2 = d.camPwr2;
                }
            })
        },
        // 打开导航栏右上角系统设置
        showSystem(){
            this.system.show ? this.dialog.dev=true : this.$toast('暂未开启，请检查设备配置')
        },
        // 保存系统设置相机开关
        systemSave(){
            let data ={
                devCode:this.info.devCode,
                devName:this.info.devName,
                devType:this.info.devType,
                camPwr1:this.system.camPwr1,
                camPwr2:this.system.camPwr2,
                optType:0
            }
            this.$api.PAH.devSystemSave(data).then((d)=>{
                this.$toast('相机控制发布成功');
            })
        },
        // 发送工作模式控制指令
        modeSave(){
            let data={};
            data.devCode= this.info.devCode;
            data.devName= this.info.devName;
            data.devType= this.info.devType;
            if(this.mode.workMode=='3'){
                data = {...this.mode};
                delete data.msgTime;
                delete data.msgType;
                delete data.id;
                delete data.receiveTime;
                data.devCode= this.info.devCode;
                data.devType= this.info.devType;
                data.workMode = +data.workMode;
                data.time2Mode = +data.time2Mode;
                data.time2Flash = +data.time2Flash;
                data.time3Mode = +data.time3Mode;
                data.time3Flash = +data.time3Flash;
                data.time4Mode = +data.time4Mode;
                data.time4Flash = +data.time4Flash;
                data.time5Mode = +data.time5Mode;
                data.time5Flash = +data.time5Flash;
            }else{
                data.flashMode= +this.mode.flashMode;
                data.workMode= +this.mode.workMode;
            }
            this.$api.PAH.devworkModeSave(data).then((d)=>{
                this.$toast('工作模式发布成功');
                setTimeout(()=>{
                    this.getPageInfo();
                },1000)
            })
        },
        // 发送路由控制指令
        routerSave(){
            let data = {};
            data.devCode = this.info.devCode;
            data.devName = this.info.devName;
            data.devType = this.info.devType;
            data.routerMode = +this.router.routerMode;
            data.holdTime = this.router.holdTime;
            if(this.router.routerMode == '2' || this.router.routerMode == '3'){
                data.routerTime1 = this.router.routerTime1;
                data.routerTime2 = this.router.routerTime2;
                data.routerTime3 = this.router.routerTime3;
                data.routerTime4 = this.router.routerTime4;
                data.routerTime5 = this.router.routerTime5;
            }
            this.$api.PAH.devRouterSave(data).then( d =>{
                this.$toast('路由控制发布成功');
                setTimeout(()=>{
                    this.getPageInfo();
                },1000)
            })
        },
        // 发送投影控制指令
        projectionSave(){
            let data = {};
            data.devCode = this.info.devCode;
            data.devName = this.info.devName;
            data.devType = this.info.devType;
            data.optType = this.projection.optType;
            data.brightness = this.projection.brightness;
            if(this.projection.optType == 2){                //光感控制
                data.threshold = this.projection.threshold;  
            }else if(this.projection.optType == 3){          //时间控制
                data.startTime = this.projection.startTime;  
                data.stopTime = this.projection.stopTime;
            }
            this.$api.PAH.devProjectionSave(data).then( d =>{
                this.$toast('投影控制发布成功');
                setTimeout(()=>{
                    this.getPageInfo();
                },1000)
            })
        },
        // 发送雷达控制指令
        radarSave(){
            let data = {};
            data.devCode = this.info.devCode;
            data.devName = this.info.devName;
            data.devType = this.info.devType;
            data.radarNo1 = this.radar.radarNo1;
            data.radarNo2 = this.radar.radarNo2;
            data.radarNo3 = this.radar.radarNo3;
            data.radarNo4 = this.radar.radarNo4;
            this.$api.PAH.devRadarSave(data).then( d =>{
                this.$toast('雷达控制发布成功');
                setTimeout(()=>{
                    this.getPageInfo();
                },1000)
            })
        },
        // 发送播报控制指令
        broadcastSave(){
            let data = {};
            data.devCode = this.info.devCode;
            data.devName = this.info.devName;
            data.devType = this.info.devType;
            data.optType = +this.broadcast.optType;
            data.volume = this.broadcast.volume;
            data.broadcast = this.broadcast.broadcast;
            if(this.broadcast.optType == '2'){                //光感控制
                data.threShold = this.broadcast.threShold;  
            }else if(this.broadcast.optType == '3'){          //时间控制
                data.startTime = this.broadcast.startTime;  
                data.stopTime = this.broadcast.stopTime;
            }
            this.$api.PAH.devSpeakerSave(data).then( d =>{
                this.$toast('播报控制发布成功');
                setTimeout(()=>{
                    this.getPageInfo();
                },1000)
            })
        },
        // 获取设备曲线
        getBattery(){
            let data ={
                devCode:this.$route.query.devCode
            }
            this.$api.PAH.BatteryCurve(data).then((d)=>{
                if(Array.isArray(d)){
                    
                    d[0].batPow === null ? d.shift() : void 0;
                    d[d.length - 1].batPow === null ? d.pop() : void 0;

                    this.active.chartData = d; 
                    this.active.waitHint = false; //优化 按钮点击过早-提示请稍等，优化用户体验
                }
            })
        },
        // 绘制电量曲线
        drawChart(){
            if(this.active.waitHint){
                this.$toast('请稍等...');
                return false;
            }
            if(this.active.chartData.length==0){
                this.$toast('电量暂无数据');
                return false;
            }
            this.active.chartShow = true;
            this.$nextTick(()=>{
                const chart = new F2.Chart({
                    id: 'batteryCurve',
                    pixelRatio: window.devicePixelRatio
                });

                const defs = {
                    recordTime: {
                        type: 'timeCat',
                        // mask: 'MM/DD',
                        mask: 'HH',
                        tickCount: 10,
                        range: [ 0, 1 ]
                    },
                    batPow: {
                        tickCount: 5,
                        min: 0,
                        alias: '电量'
                    }
                };
                // 坐标系X轴 文本对齐
                chart.axis('recordTime', {
                    label: function label(text, index, total) {
                        const textCfg = {};
                        if (index === 0) {
                        textCfg.textAlign = 'left';
                        }
                        if (index === total - 1) {
                        textCfg.textAlign = 'right';
                        }
                        return textCfg;
                    }
                });
                chart.source(this.active.chartData, defs);
                // 添加图例
                chart.legend({
                    custom: true,
                    position: 'top',
                    align:'right',
                    items: [
                        { name: '电量曲线', marker: 'square', fill: '#096DD9'},
                    ]
                });
                chart.tooltip({
                    showCrosshairs: true,
                    // 提示条点击显示具体时间
                    onShow: function onShow(ev) {
                        const items = ev.items;
                        items[0].name = items[0].origin.recordTime;
                    }
                });
                chart.area().position('recordTime*batPow');
                chart.line().position('recordTime*batPow').shape('smooth');
                chart.render();  
            })
        }
    },
    mounted(){
        this.getPageInfo().then((d) =>{
            this.getSystem();
            this.getProgramList();
            this.getBattery();
        })
    },
    beforeRouteEnter(to, from, next) {
        document.querySelector('body').setAttribute('style', 'background-color:#ffffff');
        next();
    },
    beforeRouteLeave(to, from, next) {
        document.querySelector('body').setAttribute('style', 'background-color:#f0f0f0');
        next();
    }
}
</script>

<style lang="scss" scoped>
.title{
    color: #2F9E73;
    font-weight: bold;
    font-size: 1rem;
    p{margin: 8px;}
}
.panel{
    padding: 2%;
    position: relative;
    img{width: 100%;height: 17rem;}
    #batteryCurve{width: 100%;height: 17rem;}
    .radar-location{
        position:absolute;
        left: 5%;
        top: 35%;
        z-index: 5;
        font-size: .85rem;
        .name{color: #2F9E73;}
        .radar-status{
            display: flex;color: #8b8b8b;
            .square{width: 10px;height: 10px;background-color: #ccc;display: inline-block;margin:0 10px 0 2px;}
            .working{background-color: #488BDC;}
        }
        img{width: 110px;height: 110px;}
    }
    .icon-btn{
        position:absolute;
        right: 5%;
        top: 35%;
        button{display: block;margin-bottom: 10px;}
    }
}
.info{
    &>div{margin-bottom: 2%;}
    .red{color:red}
    padding: 2%;
    font-size: .85rem;
    text-align: left;
}
.nav{
    position: absolute;
    bottom: 2px;
    width: 100%;
    font-size: .85rem;
    color: #8b8b8b;
    .fa{font-size: 1.5rem;margin-bottom: 2px;}
}
.margin-block{
    margin: 4%;
    font-size: .85rem;
    .van-cell{padding: 6px 16px;}
    .time{text-align: left;margin-bottom: 2%;}
    .title-small{color: rgb(119, 119, 119);}
    .flex-x{display: flex;margin-bottom:2%;}
    .flex{display: flex;padding: 2%;text-align: left;}
    .w_20{width: 20%;}
    .w_20-2{width: 20%;text-align: left;}
    .w_80{width: 74%;margin-top: 2%;padding-left: 4%;}
    .w_80-2{width: 76%;}
    .fa{font-size: 1.5rem;}
    .fa-spinner{font-size: 1.6rem;margin-bottom: 10%;}
    .custom-button {
        width: 26px;
        color: #fff;
        font-size: 10px;
        line-height: 18px;
        text-align: center;
        background-color: #2F9E73;
        border-radius: 100px;
    }
    .pad-2{padding: 2%;}
}
.userActive{color: #2F9E73;}
.van-radio-group--horizontal{margin: 5% 2%;}
.margin-0{margin: 0;}
.space-around{justify-content: space-around}
// 兼容iphone6以下 的小屏手机
@media screen and (max-width:350px) {
    .title{font-size: .85rem;}
    .info{margin-bottom: 10%;font-size: .75rem;}
    .nav{
        position: relative !important;
        font-size: .75rem;
        .fa{font-size: 1.3rem;}
    }
    .panel{
        img{width: 100%;height: 14.5rem;}
        #batteryCurve{width: 100%;height: 14.5rem;}
    }
}
// 兼容 370以下小屏手机
@media screen and (max-width:370px) {
    .title{font-size: .85rem;}
    .info{margin-bottom: 10%;font-size: .75rem;}
    .nav{
        font-size: .75rem;
        .fa{font-size: 1.3rem;}
    }
    .panel{
        img{width: 100%;height: 15.5rem;}
        #batteryCurve{width: 100%;height: 15.5rem;}
    }
}
// 兼容iphone6/7/8
//  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-device-pixel-ratio: 2) {
//     .panel{
//         img{width: 100%;height: 16rem;}
//         #batteryCurve{width: 100%;height: 16rem;}
//     }
//     // 修复ios底部自动生成导航栏影响的布局
//     .nav{
//         position: relative !important;
//         margin-top: 8%;
//     }
//  }
</style>